<template>
  <section id="profileUser" class="py-5 px-2">
    <template v-if="!loder">
      <div class="flex justify-end pb-5">
        <dok-button
          size="md"
          custom-class="font-EffraM text-lg flex items-center gap-x-2"
          bg="white"
          @click.native="
            $router.push({
              name: 'profileDoctor',
              params: { slug: $auth.getUserInfo().slug },
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <g
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              clip-path="url(#clip0)"
            >
              <path
                d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z"
              />
              <path
                d="M9 11.25c1.243 0 2.25-1.007 2.25-2.25S10.243 6.75 9 6.75 6.75 7.757 6.75 9 7.757 11.25 9 11.25z"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 0H18V18H0z" />
              </clipPath>
            </defs>
          </svg>

          Voir aperçu de mon profil public
        </dok-button>
      </div>
      <div class="flex flex-col md:flex-row items-start gap-y-2 gap-x-5">
        <!----------Avatar Upload------------>
        <div class="flex justify-center w-full md:w-auto">
          <avatar-upload
            :param-file.sync="user.image"
            :active-upload="user._id === $auth.getUserId()"
            :run-save-function="onSave"
            :reSize="true"
          ></avatar-upload>
        </div>
        <!----------End Avatar Upload------------>
        <div class="flex flex-col gap-y-4 w-full">
          <!----------FULLNAME------------>
          <div class="flex flex-row gap-x-2 w-full">
            <template v-if="actions.edit !== 'fullName'">
              <div class="flex flex-row gap-x-2 justify-between w-full">
                <h3 class="flex-1 font-EffraR text-lg">
                  {{
                    _().capitalize(user.title) ? $RoleType(user.title) : "Dr."
                  }}
                  {{ user.firstName }}
                  {{ user.lastName }}
                </h3>
                <i
                  class="
                    fas
                    fa-pencil-alt
                    hover:text-dokBlue-ultra
                    cursor-pointer
                  "
                  alt="edit"
                  @click="onEdit('fullName')"
                ></i>
              </div>
            </template>
            <template v-if="actions.edit === 'fullName'">
              <div
                class="
                  flex flex-col
                  md:flex-row
                  justify-start
                  md:justify-between
                  items-center
                  w-full
                  gap-y-2 gap-x-2
                "
              >
                <div class="flex flex-col md:flex-row gap-x-2 gap-y-2">
                  <select
                    class="
                      bg-transparent
                      border-2 border-solid border-borderInput
                      rounded
                      px-1
                      w-auto
                      font-EffraR
                      text-black
                      py-3
                      focus:outline-none
                    "
                    v-model="user.title"
                  >
                    <option value="DR">Dr.</option>
                    <option value="PR">Pr.</option>
                    <option value="PH">Ph.</option>
                    <option value="M">M.</option>
                    <option value="Mme">Mme.</option>
                  </select>
                  <dok-input
                    size="lg"
                    d-placeholder="Prenom"
                    d-type="text"
                    :d-model.sync="user.firstName"
                  >
                  </dok-input>
                  <dok-input
                    size="lg"
                    d-placeholder="Nom"
                    d-type="text"
                    :d-model.sync="user.lastName"
                  >
                  </dok-input>
                </div>
                <div class="flex flex-row gap-x-2">
                  <dok-button type="button" size="md" @click.native="onSave">
                    Sauvegarder
                  </dok-button>
                  <dok-button
                    type="button"
                    size="md"
                    bg="rose"
                    @click.native="
                      () => {
                        actions.edit = null;
                      }
                    "
                  >
                    Restaurer
                  </dok-button>
                </div>
              </div>
            </template>
          </div>
          <!----------END FULLNAME------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!----------ADDRESS------------>
          <template v-if="actions.edit === 'address'">
            <div class="flex flex-col gap-y-3">
              <dok-input
                size="lg"
                d-placeholder="Adresse"
                d-type="text"
                :d-model.sync="user.landingPage.address.label"
                label="Adresse"
              >
                <template v-slot:rightIcon>
                  <i
                    class="
                      fas
                      fa-search
                      hover:text-dokBlue-ultra
                      cursor-pointer
                    "
                    @click="onSerchAddress"
                  ></i>
                </template>
              </dok-input>

              <dok-input
                size="lg"
                d-placeholder="Complément d'adresse"
                d-type="text"
                :d-model.sync="user.landingPage.address2"
                label="Complément d'adresse"
              >
              </dok-input>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5">
                <div class="flex flex-col">
                  <label class="font-EffraR text-base text-gray-800"
                    >Ville</label
                  >
                  <CitiesSelect
                    class="w-full doksingupselect"
                    :selected-item.sync="user.landingPage.address.city"
                  ></CitiesSelect>
                </div>

                <div class="flex flex-col">
                  <label class="font-EffraR text-base text-gray-800"
                    >Quartier</label
                  >
                  <div class="w-full">
                    <multiselect
                      v-model="user.landingPage.address.municipality"
                      :options="quartiers"
                      :close-on-select="true"
                      placeholder="Quelle quartier ?"
                      class="w-full doksingupselect"
                    >
                      <template slot="noResult"
                        >Il n'y a aucun résultat</template
                      >
                      <template slot="noOptions"
                        >Saisissez un Quartier!</template
                      >
                    </multiselect>
                  </div>
                </div>
              </div>

              <template
                v-if="user.landingPage && user.landingPage.address.city"
              >
                <mapGoogle
                  :lat="lat"
                  :lng="lng"
                  :set-position="onSetPosition"
                  :zoom="10"
                  class="h-500"
                  :dragMark="true"
                ></mapGoogle>
                <div
                  class="
                    rounded-cu
                    bg-dokBlue-lighter
                    text-dokBlue-ultra
                    font-EffraR
                    text-lg
                    py-2
                    px-1
                  "
                >
                  <i class="fas fa-info-circle"></i> Déplacez le curseur pour
                  préciser votre localisation
                </div>
              </template>
              <div class="flex flex-row gap-x-2 justify-end">
                <dok-button type="button" size="md" @click.native="onSave">
                  Sauvegarder
                </dok-button>
                <dok-button
                  type="button"
                  size="md"
                  bg="rose"
                  @click.native="
                    () => {
                      userInfo();
                      actions.edit = null;
                    }
                  "
                >
                  Restaurer
                </dok-button>
              </div>
            </div>
          </template>
          <!----------Address------------>
          <template v-if="actions.edit !== 'address'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-rox gap-x-1">
                <strong class="font-EffraM text-base text-gray-700"
                  >Adresse:
                </strong>
                <span class="font-EffraR text-base">
                  {{ user.landingPage.address.label }}
                  {{
                    user.landingPage && user.landingPage.address2
                      ? ", " + user.landingPage.address2
                      : ""
                  }}
                  {{
                    user.landingPage &&
                    user.landingPage.address &&
                    user.landingPage.address.municipality
                      ? ", " + user.landingPage.address.municipality
                      : ""
                  }}
                  {{
                    user.landingPage.address.city
                      ? ", " + user.landingPage.address.city.name
                      : ""
                  }}
                </span>
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('address')"
              ></i>
            </div>
          </template>
          <!----------END ADDRESS------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!----------Telephone------------>
          <template v-if="actions.edit !== 'fixe'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-rox gap-x-1">
                <strong class="font-EffraM text-base text-gray-700"
                  >Téléphone:
                </strong>
                <span class="font-EffraR text-base">
                  {{ user.fix || "--" }}
                </span>
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('fixe')"
              ></i>
            </div>
          </template>
          <div
            class="
              flex flex-col
              md:flex-row
              justify-start
              md:justify-between
              items-center
              gap-x-10 gap-y-2
            "
            v-if="actions.edit === 'fixe'"
          >
            <dok-input
              size="md"
              d-placeholder="Téléphone"
              d-type="text"
              :d-model.sync="user.fix"
            >
            </dok-input>
            <div class="flex flex-row gap-x-2 justify-end">
              <dok-button type="button" size="md" @click.native="onSave">
                Sauvegarder
              </dok-button>
              <dok-button
                type="button"
                size="md"
                bg="rose"
                @click.native="
                  () => {
                    userInfo();
                    actions.edit = null;
                  }
                "
              >
                Restaurer
              </dok-button>
            </div>
          </div>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!----------Experience------------>
          <template v-if="actions.edit !== 'experience'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-rox gap-x-1">
                <strong class="font-EffraM text-base text-gray-700"
                  >Années d'expérience:
                </strong>
                <span class="font-EffraR text-base">
                  {{
                    user.landingPage.experienceStartedSince
                      ? `${formatExpirance(
                          user.landingPage.experienceStartedSince
                        )} ${
                          formatExpirance(
                            user.landingPage.experienceStartedSince
                          ) > 1
                            ? "années d'expérience"
                            : "année d'expérience"
                        } `
                      : "--"
                  }}
                </span>
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('experience')"
              ></i>
            </div>
          </template>
          <template v-if="actions.edit === 'experience'">
            <div
              class="
                flex flex-col
                md:flex-row
                items-start
                md:items-end
                gap-x-5 gap-y-2
              "
            >
              <div class="flex-1 w-full">
                <!-- DD/MM/YYYY -->
                <birthDay
                  :dateSave="experienceStartedSinceFormat"
                  :onSetDate="onSetBirthday"
                  customClass="bg-white border-2 border-solid border-borderInput rounded px-1 py-3 w-full font-EffraR text-black appearance-none"
                />
              </div>
              <div class="flex flex-row gap-x-2">
                <dok-button type="button" size="md" @click.native="onSave">
                  Sauvegarder
                </dok-button>
                <dok-button
                  type="button"
                  size="md"
                  bg="rose"
                  @click.native="
                    () => {
                      userInfo();
                      actions.edit = null;
                    }
                  "
                >
                  Restaurer
                </dok-button>
              </div>
            </div>
          </template>
          <!----------End Experience------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!----------Spécialités------------>
          <template v-if="actions.edit !== 'specialites'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-rox gap-x-1">
                <strong class="font-EffraM text-base text-gray-700"
                  >Spécialités:
                </strong>
                <span class="font-EffraR text-base">
                  {{
                    user.landingPage.specialities
                      .map((spec) => spec.name)
                      .join(" - ")
                  }}
                </span>
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('specialites')"
              ></i>
            </div>
          </template>
          <template v-if="actions.edit === 'specialites'">
            <div
              class="
                flex flex-col
                lg:flex-row
                items-center
                md:items-center
                gap-x-5 gap-y-2
              "
            >
              <div class="flex-1">
                <specialities-select
                  :selected-items.sync="user.landingPage.specialities"
                  custem-class="doksingupselect w-full"
                ></specialities-select>
              </div>
              <div class="flex flex-row gap-x-2">
                <dok-button type="button" size="md" @click.native="onSave">
                  Sauvegarder
                </dok-button>
                <dok-button
                  type="button"
                  size="md"
                  bg="rose"
                  @click.native="
                    () => {
                      userInfo();
                      actions.edit = null;
                    }
                  "
                >
                  Restaurer
                </dok-button>
              </div>
            </div>
          </template>
          <!----------End Spécialités------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!---------- Actes ------------>
          <template v-if="actions.edit !== 'actes'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-rox gap-x-1">
                <strong class="font-EffraM text-base text-gray-700"
                  >Actes médicaux:
                </strong>
                <span class="font-EffraR text-base">
                  {{
                    user.landingPage.acts.length > 0
                      ? user.landingPage.acts.map((act) => act.name).join(" - ")
                      : "--"
                  }}
                </span>
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('actes')"
              ></i>
            </div>
          </template>
          <template v-if="actions.edit === 'actes'">
            <div
              class="
                flex flex-col
                lg:flex-row
                items-center
                md:items-center
                gap-x-5 gap-y-2
              "
            >
              <div class="flex-1">
                <SpecialitiesSelectActs
                  :selected-items="user.landingPage.specialities"
                  :selectedActsItems.sync="user.landingPage.acts"
                  :acts="acts"
                  custem-class="doksingupselect w-full"
                >
                </SpecialitiesSelectActs>
              </div>
              <div class="flex flex-row gap-x-2">
                <dok-button type="button" size="md" @click.native="onSave">
                  Sauvegarder
                </dok-button>
                <dok-button
                  type="button"
                  size="md"
                  bg="rose"
                  @click.native="
                    () => {
                      userInfo();
                      actions.edit = null;
                    }
                  "
                >
                  Restaurer
                </dok-button>
              </div>
            </div>
          </template>
          <!----------End Actes------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!----------Parcours------------>
          <template v-if="actions.edit !== 'parcours'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-rox gap-x-1">
                <strong class="font-EffraM text-base text-gray-700"
                  >Présentation:
                </strong>
                <span
                  class="font-EffraR text-base break-words whitespace-pre-line"
                >
                  {{ user.doctorProfile.experienceBackground || "--" }}
                </span>
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('parcours')"
              ></i>
            </div>
          </template>
          <template v-if="actions.edit === 'parcours'">
            <div class="flex flex-col lg:flex-row items-start gap-x-5 gap-y-2">
              <div class="flex-1 w-full">
                <textarea
                  placeholder="Écrivez quelques mots…"
                  v-model="user.doctorProfile.experienceBackground"
                  class="
                    border-2 border-solid border-borderInput
                    w-full
                    p-1
                    resize-none
                    h-29
                  "
                ></textarea>
              </div>
              <div class="flex flex-row gap-x-2">
                <dok-button
                  type="button"
                  size="md"
                  @click.native="onSave('parcours')"
                >
                  Sauvegarder
                </dok-button>
                <dok-button
                  type="button"
                  size="md"
                  bg="rose"
                  @click.native="
                    () => {
                      userInfo();
                      actions.edit = null;
                    }
                  "
                >
                  Restaurer
                </dok-button>
              </div>
            </div>
          </template>
          <!----------End Parcours------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!----------Diplômes------------>
          <template v-if="actions.edit !== 'diplomes'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-col gap-x-1">
                <div>
                  <strong class="font-EffraM text-base text-gray-700"
                    >Diplômes:
                  </strong>
                </div>
                <!--
              -->
                <pre
                  class="font-EffraR text-base break-words whitespace-pre-line"
                >
                {{ user.doctorProfile.experienceDiplomes || "--" }}
              </pre
                >
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('diplomes')"
              ></i>
            </div>
          </template>
          <template v-if="actions.edit === 'diplomes'">
            <div class="flex flex-col lg:flex-row items-start gap-x-5 gap-y-2">
              <div class="flex-1 w-full">
                <textarea
                  placeholder="Écrivez quelques mots…"
                  v-model="user.doctorProfile.experienceDiplomes"
                  class="
                    border-2 border-solid border-borderInput
                    w-full
                    p-1
                    resize-none
                    h-29
                  "
                ></textarea>
              </div>
              <div class="flex flex-row gap-x-2">
                <dok-button
                  type="button"
                  size="md"
                  @click.native="onSave('diplomes')"
                >
                  Sauvegarder
                </dok-button>
                <dok-button
                  type="button"
                  size="md"
                  bg="rose"
                  @click.native="
                    () => {
                      userInfo();
                      actions.edit = null;
                    }
                  "
                >
                  Restaurer
                </dok-button>
              </div>
            </div>
          </template>
          <!----------End Diplômes------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!----------Languages------------>
          <template v-if="actions.edit !== 'languages'">
            <div class="flex flex-row">
              <div class="flex-1 flex flex-rox gap-x-1">
                <strong class="font-EffraM text-base text-gray-700"
                  >Langues parlées:
                </strong>
                <span class="font-EffraR text-base">
                  {{ user.landingPage.languages.join(" - ") }}
                </span>
              </div>
              <i
                class="
                  fas
                  fa-pencil-alt
                  hover:text-dokBlue-ultra
                  cursor-pointer
                "
                alt="edit"
                @click="onEdit('languages')"
              ></i>
            </div>
          </template>
          <template v-if="actions.edit === 'languages'">
            <div class="flex flex-col lg:flex-row items-start gap-x-5 gap-y-2">
              <div class="flex-1 w-full">
                <multiselect
                  v-model="user.landingPage.languages"
                  :options="$langs"
                  :multiple="true"
                  placeholder="Choisissez les langues"
                  class="doksingupselect"
                ></multiselect>
              </div>
              <div class="flex flex-row gap-x-2">
                <dok-button type="button" size="md" @click.native="onSave">
                  Sauvegarder
                </dok-button>
                <dok-button
                  type="button"
                  size="md"
                  bg="rose"
                  @click.native="
                    () => {
                      userInfo();
                      actions.edit = null;
                    }
                  "
                >
                  Restaurer
                </dok-button>
              </div>
            </div>
          </template>
          <!----------End Languages------------>
          <hr class="border-0 border-b border-solid border-grayTable" />
          <!-- Teleconsultation  -->
          <template v-if="actions.edit !== 'tarifs'">
            <div class="flex flex-col gap-y-2">
              <div class="flex flex-row justify-between">
                <strong class="flex-1 font-EffraM text-base text-gray-700"
                  >Tarifs :</strong
                >
                <i
                  class="
                    fas
                    fa-pencil-alt
                    hover:text-dokBlue-ultra
                    cursor-pointer
                  "
                  alt="edit"
                  @click="onEdit('tarifs')"
                ></i>
              </div>
              <div v-for="(category, index) in filterCatgorys" :key="index">
                <div class="flex justify-between w-full font-EffraR">
                  <span class="text-lg" style="color: #4f5968">{{
                    category.title
                  }}</span>
                  <span class="text-lg" style="color: #4f5968"
                    >{{
                      category.price === 0 ? "Gratuit" : `${category.price} DH`
                    }}
                  </span>
                </div>
              </div>
              <div class="font-EffraR text-lg leading-normal">
                Le praticien est tenu de prévenir le patient à l'avance de tout
                changement de tarifs.
              </div>
            </div>
          </template>
          <template v-if="actions.edit === 'tarifs'">
            <dok-input
              size="lg"
              d-placeholder="Tarif d'une consultation de base au cabinet"
              d-type="number"
              :d-model.sync="user.doctorProfile.consultationPrice"
              label="Tarif d'une consultation de base au cabinet"
            >
              <template v-slot:rightIcon>
                <i
                  class="far fa-question-circle cursor-pointer"
                  v-tooltip="$getInfoBull('priceStandarConsultation')"
                ></i>
              </template>
            </dok-input>

            <div class="flex flex-col gap-x-3">
              <label class="text-xl text-gray-700 font-EffraR"
                >Faites-vous des téléconsultations?</label
              >
              <div
                class="flex flex-row gap-x-2"
                v-if="user.doctorProfile.videoConsultation"
              >
                <div class="flex flex-row items-center gap-x-2">
                  <input
                    id="checkTeleconsultation"
                    type="radio"
                    class="
                      form-radio
                      border-2 border-solid border-dokBlue-ultra
                      text-xl
                      focus:outline-none
                    "
                    v-model="videoConsultationIs"
                    :value="true"
                  />
                  <label
                    for="checkTeleconsultation"
                    class="font-semibold text-base cursor-pointer"
                    >Oui</label
                  >
                </div>
                <div class="flex flex-row items-center gap-x-2">
                  <input
                    id="unCheckTeleconsultation"
                    type="radio"
                    class="
                      form-radio
                      border-2 border-solid border-dokBlue-ultra
                      text-xl
                      focus:outline-none
                    "
                    v-model="videoConsultationIs"
                    :value="false"
                  />
                  <label
                    for="unCheckTeleconsultation"
                    class="font-semibold text-base cursor-pointer"
                    >Non</label
                  >
                </div>
              </div>
            </div>

            <dok-input
              v-if="user.doctorProfile.videoConsultation.active"
              size="lg"
              d-placeholder="Tarif d'une téléconsultation"
              d-type="number"
              :d-model.sync="user.doctorProfile.videoConsultation.price"
              label="Tarif d'une téléconsultation"
            >
              <template v-slot:rightIcon>
                <i
                  class="far fa-question-circle cursor-pointer"
                  v-tooltip="$getInfoBull('priceTeleconsultation')"
                ></i>
              </template>
            </dok-input>
            <div class="flex items-center gap-x-3">
              <div
                class="h-3 w-3 bg-dokBlue-ultra rounded-full animate-ping"
              ></div>
              <span class="text-base font-normal text-gray-600 font-EffraR">
                N'oubliez pas de saisir vos informations bancaires dans
                paramètres pour recevoir les paiements des téléconsultations. Si
                vous souhaitez les faire gratuitement, mettez 0 dans la case.
              </span>
            </div>
            <div class="flex flex-row gap-x-2 justify-end">
              <dok-button
                type="button"
                size="md"
                @click.native="onSave('tarifs')"
              >
                Sauvegarder
              </dok-button>
              <dok-button
                type="button"
                size="md"
                bg="rose"
                @click.native="
                  () => {
                    userInfo();
                    actions.edit = null;
                  }
                "
              >
                Restaurer
              </dok-button>
            </div>
          </template>
          <!-- End Teleconsultation  -->
        </div>
      </div>
    </template>

    <template v-if="loder">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block; shape-rendering: auto"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
        </circle>
        <circle cx="70" cy="50" fill="#2e6bff" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
    </template>
  </section>
</template>

<script>
  import { get as getKey } from "lodash";
  import { EventBus } from "@/event-bus";
  import moment from "moment";

  const mapGoogle = () => import("@/views/global-components/map/index");
  const SpecialitiesSelect = () =>
    import("@/views/global-components/SpecialitiesSelect");
  const SpecialitiesSelectActs = () =>
    import("@/views/global-components/SpecialitiesSelectActs");
  const CitiesSelect = () => import("@/views/global-components/CitiesSelect");
  const avatarUpload = () => import("@/views/global-components/uploadAvatar.vue");
  const birthDay = () => import("@/views/global-components/birthday");

  export default {
    components: {
      mapGoogle,
      SpecialitiesSelect,
      SpecialitiesSelectActs,
      CitiesSelect,
      avatarUpload,
      birthDay,
    },
    data() {
      return {
        loder: true,
        user: {},
        motifs: [],
        actions: {
          edit: null,
        },
        lat: 0,
        lng: 0,
        acts: [],
        quartiers: [],
      };
    },
    computed: {
      videoConsultationIs: {
        get() {
          return this.user.doctorProfile.videoConsultation.active;
        },
        set(value) {
          this.user.doctorProfile.videoConsultation.active = value;
        },
      },
      experienceStartedSinceFormat: {
        get() {
          return this.user.landingPage &&
            this.user.landingPage.experienceStartedSince
            ? moment(this.user.landingPage.experienceStartedSince)
            : moment();
        },
        set(value) {
          this.user.landingPage.experienceStartedSince = value;
        },
      },
      filterCatgorys() {
        let arrayPush = [];
        this.motifs.map((cat) => {
          if (cat.price != null && cat.price > -1) {
            arrayPush.push(cat);
          }
        });
        return arrayPush;
      },
    },

    created() {
      this.userInfo();
      this.userMotifs();
    },

    watch: {
      "user.landingPage.specialities"(newVal, oldVal) {
        this.getActs();
      },
      "user.landingPage.address.city.name"(newVal, oldVal) {
        this.getQuartier();
      },
    },

    methods: {
      async getActs() {
        var { ok, data } = await this.$store.dispatch(
          "doctor/GET_ACTS_BY_SPECIALITY",
          {
            specs: this.user.landingPage.specialities,
          }
        );
        if (ok) {
          this.acts = data;
        } else {
          this.acts = [];
        }
      },

      async getQuartier() {
        this.quartiers = [];
        if (this.user.landingPage.address.city.name) {
          const data = await this.$store.dispatch(
            "location/GET_CITIES_QUARTIERS",
            { city: this.user.landingPage.address.city.name }
          );
          if (data.ok) {
            this.quartiers = data.data;
          }
        }
      },

      async userInfo() {
        let _this = this;
        this.$store.dispatch("doctor/GET_BY_ID", {
          onData: ({ ok, data }) => {
            if (ok) {
              // Set Doctor Profile First
              if (!data.doctorProfile.videoConsultation) {
                data.doctorProfile.videoConsultation = {
                  active: false,
                  price: null,
                };
              }

              this.lat = parseFloat(getKey(data, "landingPage.address.lat"));
              this.lng = parseFloat(getKey(data, "landingPage.address.lng"));

              this.user = data;
              this.loder = false;
            }
          },
          id: _this.$auth.getUserInfo()._id,
        });
      },

      async userMotifs() {
        this.$store.dispatch("agenda/GET_CATEGORIES", {
          onData: ({ ok, data }) => {
            if (ok) {
              this.motifs = data;
            }
          },
        });
      },

      async onSave(type = null) {
        this.$vs.loading();
        try {
          if (type === "tarifs" || type === "parcours" || type === "diplomes") {
            await this.$store.dispatch("doctor/SAVE_PROFILE", {
              params: { ...this.user.doctorProfile },
              onData: ({ ok, data }) => {
                if (ok) {
                  if (type === "tarifs") {
                    this.userMotifs();
                  } else {
                    this.$nextTick(() => {
                      EventBus.$emit("Emit_Refresh_navbar");
                    });
                  }

                  this.actions.edit = null;
                  this.$vs.loading.close();

                  return;
                }
              },
            });
            return EventBus.$emit("flashDok", {
              type: "success",
              message: `${type} modifié avec succès!`,
              timerAlert: 8000,
            });
          }

          if (!this.user.firstName || !this.user.lastName) {
            setInterval(() => {
              this.$vs.loading.close();
            }, 100);
            return EventBus.$emit("flashDok", {
              type: "errorr",
              message: "Tous les champs sont obligatoires",
              timerAlert: 8000,
            });
          }

          await this.$store.dispatch("user/UPDATE_PROFILE", {
            data: { ...this.user },
            onData: ({ ok, data, message }) => {
              this.$vs.loading.close();
              if (ok) {
                this.actions.edit = null;
                this.$auth.setUserInfo(data);
                EventBus.$emit("flashDok", {
                  type: "success",
                  message: "Modification effectuée avec succès!",
                  timerAlert: 8000,
                });
              } else {
                EventBus.$emit("flashDok", {
                  type: "errorr",
                  message: message,
                  timerAlert: 8000,
                });
              }
            },
          });
        } catch (error) {
          this.$vs.loading.close();
        }
      },

      async onSerchAddress() {
        if (!this.user.landingPage.address.label) {
          return;
        }
        const { data } = await this.$http.get("/location", {
          params: {
            label: this.user.landingPage.address.label,
            cityName: this.user.landingPage.address.city.name,
          },
        });

        if (data.ok) {
          this.lat = data.data.lat;
          this.lng = data.data.lng;
          this.user.landingPage.address.lat = data.data.lat;
          this.user.landingPage.address.lng = data.data.lng;
          this.$forceUpdate();
        }
      },

      onSetBirthday(date) {
        if (date) {
          let currentDate = moment(date, "YYYY-MM-DD");
          if (currentDate.isValid) {
            this.user.landingPage.experienceStartedSince = currentDate.toDate();
          }
        }
      },

      onEdit(type) {
        if (this.actions.edit === type) {
          this.actions.edit = null;
          return;
        }

        this.actions.edit = type;
      },

      onSetPosition({ lat, lng }) {
        this.lat = lat;
        this.lng = lng;
        this.user.landingPage.address.lat = lat;
        this.user.landingPage.address.lng = lng;
        EventBus.$emit("flashDok", {
          type: "success",
          message: "Localisation précisée avec succès!",
          timerAlert: 8000,
        });
      },

      formatExpirance(dateString) {
        return moment().diff(dateString, "years", false);
      },

      _() {
        return _;
      },
    },
  };
</script>

<style>
.doksingupselect .multiselect__tag-icon:hover {
  background: #97b5ff !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tag {
  background: #e4e4e4 !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tags {
  min-height: 50px !important;
}

.doksingupselect .multiselect__tags {
  border: 2px solid #e4e4e4 !important;
}

.doksingupselect .multiselect__select {
  height: 45px !important;
}

.doksingupselect .multiselect__placeholder {
  padding-top: 5px !important;
  @apply text-gray-600 text-lg;
}

.doksingupselect .multiselect__input,
.doksingupselect .multiselect__single {
  line-height: 30px;
  @apply font-EffraR;
}

.doksingupselect .multiselect__option--highlight {
  @apply bg-dokBlue-light;
}
.doksingupselect .multiselect__option--selected.multiselect__option--highlight {
  @apply bg-rosDok-light;
}
</style>
